<template>
  <div>
    <div class="row page-title">
      <label class="Agency-Name-HFM-Co"
        >{{ ProfileUnlockRequestDetail.Agency }} - Profile UnLock Request</label>
    </div>
    <div class="row icreview-component">
      <b-card class="col-lg-9 main-content">
        <div class="panel-list">
          <div class="panel-body">
            <profile-unlock-overview :ProfileUnlockRequestDetail = ProfileUnlockRequestDetail></profile-unlock-overview>
          </div>
        </div>
      </b-card>
      <div class="col-lg-3 approval-side-section">
        <div class="row">
          <p class="approve-reject-label">
            Approve or Reject Profile Unlock Request
          </p>
          <p class="approve-reject-content">
            When approved, this request will be sent to the Financial Systems
            Team to  approve/unlock.
          </p>
          <div class="text-right" style="width: 100%">
            <button  v-if="IsApproveOrRejectEnabled" class="btn custom-button btn-primary" @click="Approve()">
                Approve
              </button>
              <button
            v-else
            class="btn custom-button btn-primary"
            disabled="disabled"
            title="The request is already approved or rejected."
          >
            Approve
          </button>
          </div>
        </div>
        <div class="row reject-section">
          <label class="rejection-label">Rejection Comments</label>
          <b-textarea
            class="rejection-comments"
            placeholder="Enter Rejection Comments"
            v-model="Comments"
            v-bind:class="{
              errorborder: displaycommentserror && showCommentsError,
              noterrorborder: !displaycommentserror || !showCommentsError,
            }"
            @blur="SetDirty()"
            @focus="ResetDirty()"
            rows="5"
          >
          </b-textarea>
          <div
            class="error-message-format required-field"
            v-if="displaycommentserror && showCommentsError"
          >
            Required field
          </div>
          <div class="text-right" style="width: 100%; padding-top: 10px">
            <button  v-if="IsApproveOrRejectEnabled"
                class="btn custom-button btn-outline-primary"
                @click="Reject()"
              >
                Reject
              </button>
              <button
          v-else
          class="btn custom-button btn-outline-primary"
          disabled="disabled"
          title="The request is already approved or rejected."
        >
          Reject
        </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scope >
@media screen and (min-width: 1200px) {
  .icreview-component {
    padding: 1rem 3rem 0px !important;
    height: 600px !important;
  }
}
.Agency-Name-HFM-Co {
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.73px;
  color: #4d5161;
}
.approval-side-section {
  background-color: rgba(162, 169, 173, 0.05);
  margin-bottom: 2px;
  padding-right: 35px !important;
  -webkit-box-shadow: 0 1px 3px 0 rgb(9 30 66 / 12%);
  box-shadow: 0 1px 3px 0 rgb(9 30 66 / 12%);
  border: solid 1px rgba(239, 242, 244, 0.2);
  border-left: none;
  border-radius: 0px 8px 8px 0px;
}
.row.page-title {
  height: 55px;
  background-color: #eef3f5 !important;
}
.row.page-title > label {
  padding: 14px 0px 14px 55px !important;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.81px;
  color: #4d5161;
  text-transform: none !important;
}
.panel-list:not(.media-dropdowns) {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.panel-list .panel-body .panel-title {
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
  width: 100%;
  margin-left: 0px;
  margin-bottom: 25px !important;
}
.panel-list .panel-body .panel-title label {
  color: #77a2bb;
  margin-bottom: 0px !important;
  padding-top: 13px !important;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.57;
  letter-spacing: normal;
  padding-left: 0px !important;
  min-height: 35px;
}
.panel-list .panel-body .panel-title .btn {
  padding-left: 5px;
  padding-right: 5px;
}
.panel-list .panel-body .panel-title > div {
  padding-right: 0px !important;
  float: right;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  color: #00a1d2;
}
.panel-list .panel-content label {
  text-transform: uppercase;
  color: #2c3865;
  font-size: 12px;
  margin-bottom: 1px !important;
  padding-left: 0px;
}
.panel-list .panel-content p {
  text-transform: uppercase;
  color: #4d5161;
  font-size: 14px;
  font-weight: 600;
}
.panel-list > .panel-body {
  margin-top: 1rem !important;
}
</style>

<script>
import store from "@/store";
import { required } from "vuelidate/lib/validators";
import ProfileUnlockOverview from "@/components/Profile/Overview";
import * as ProfileUnlockStatus from "../../utilities/ProfileUnlockStatus";
export default {
  components: {
    "profile-unlock-overview": ProfileUnlockOverview
  },
  validations: {
    Comments: {
      required,
    },
  },
  data() {
    return {
      displaycommentserror: false,
      ProfileUnlockRequestDetail:{},
      Error: false,
      Comments: "",
      showCommentsError: false,
    };
  },

  mounted() {
    if (this.canApproveProfileRequestLevel1  || this.canApproveProfileRequestLevel2) {
      this.GetProfileUnlockRequestWithTaskDetails();
    }
    else {
      this.$router.push({
        name: "error",
        params: { message: "UnAuthorized" },
      });
    }
  },
  computed: {
    IsApproveOrRejectEnabled() {
      return (
        (this.canApproveProfileRequestLevel1 && this.ProfileUnlockRequestDetail.Status==ProfileUnlockStatus.ApproveLevel1)
        || (this.canApproveProfileRequestLevel2 && this.ProfileUnlockRequestDetail.Status==ProfileUnlockStatus.ApproveLevel2)
      );
    },
    canApproveProfileRequestLevel1() {
      return this.$store.getters.canApproveProfileRequestLevel1;
    },
    canApproveProfileRequestLevel2() {
      return this.$store.getters.canApproveProfileRequestLevel2;
    },
    },
  props: ["requestnumber"],

  methods: {
    SetDirty() {
      if (this.Comments.length > 0) {
        this.showCommentsError = false;
      } else {
        this.showCommentsError = true;
      }
    },
    ResetDirty() {
      this.showCommentsError = false;
    },
    GetData() {
      return {
        ID: parseInt(this.requestnumber),
        Comments: this.Comments,
        ActiveTaskID: this.ProfileUnlockRequestDetail.ActiveTaskID,
        ProjectID: this.ProfileUnlockRequestDetail.OmniflowProjectID,
        ProcessID: this.ProfileUnlockRequestDetail.OmniflowProcessID,
      };
    },

    Approve() {
      this.$store
        .dispatch("client/ApproveProfileUnlockRequestLevel1", this.GetData())
        .then(() => {
          store.dispatch(
            "progress/setSuccessMessage",
            `The ${this.ProfileUnlockRequestDetail.Agency} Profile Unlock has been approved.`
          );
          this.$router.push("/ProfileUnlock");
        })
        .catch((ex) => {
          if (ex.response) {
            this.SetErrorMessage(
              "Error while approving request",
              ex.response.data
            );
          } else {
            this.SetErrorMessage("Error while approving request", ex);
          }
        });
    },

    Reject() {
      if (this.Comments.length > 0) {
        this.displaycommentserror = false;
        this.$store
          .dispatch("client/RejectProfileUnlockRequestLevel1", this.GetData())
          .then(() => {
            store.dispatch(
              "progress/setSuccessMessage",
              `Profile Unlock Request for ${this.ProfileUnlockRequestDetail.Agency} has been rejected`
            );
            this.$router.push("/ProfileUnlock");
          })
          .catch((ex) => {
            window.console.log(JSON.stringify(ex.response));
            if (ex.response) {
              this.SetErrorMessage(
                "Error while rejecting request",
                ex.response.data
              );
            } else {
              this.SetErrorMessage("Error while rejecting request", ex);
            }
          });
      } else {
        this.displaycommentserror = true;
        this.showCommentsError = true;
      }
    },

    SetErrorMessage(message, ex) {
      store.dispatch(
        "progress/setErrorMessage",
        `${message} ${this.ProfileUnlockRequestDetail.ExpectedHFMCode}. The error is ${ex}`
      );
    },

    GetProfileUnlockRequestWithTaskDetails() {
      var inputObj = {
                id: parseInt(this.requestnumber),
                isedit: false,
            };
      this.$store
        .dispatch(
          "client/GetProfileUnlockRequestWithTaskDetails",
          inputObj
        )
        .then((res) => {
          this.ProfileUnlockRequestDetail = res.data;
        })
        .catch((err) => {
          if (err.response && err.response.status == 403) {
            this.$router.push({
              name: "error",
              params: {
                message: "UnAuthorized"
              },
            });
          }
          else{
            this.Error = true;
            alert(err);
          }
        });
    },
  },
};
</script>